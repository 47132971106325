<template>
  <b-card>
    <template #header> <h4>Müşteri Bilgileri</h4> </template>
    <b-form>
      <b-form-group>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="datasource.customer.customer_type"
            name="scustomer_type"
            value="0"
          >
            Kişi Kartı Oluştur
          </b-form-radio>
          <b-form-radio
            v-model="datasource.customer.customer_type"
            name="scustomer_type"
            value="1"
          >
            Firma Kartı Oluştur
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group v-if="false">
        <div class="alert alert-primary p-1">
          <feather-icon
            icon="AlertCircleIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          Bu bilgileri müşteriniz kendi profil sayfanızdan değiştirebilir.
        </div>
      </b-form-group>
      <b-row>
        <b-col col lg="6" sm="12">
          <h5 class="">Müşteri Bilgileri</h5>
          <div class="cardbody mt-1">
            <b-row>
              <b-col lg="12" sm="12">
                <b-form-group label="Müşteri Kodu">
                  <b-form-input
                    v-model="datasource.customer.customer_code"
                    class="bg-disabled"
                    placeholder="6 haneli müşteri kodu"
                    autocomplate="off"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="12" sm="12">
                <b-form-group
                  v-if="datasource.customer.customer_type == 1"
                  label="Bağlı Olduğu Firma"
                >
                  <vue-autosuggest v-if="false" />
                </b-form-group>
                <b-form-group
                  v-if="datasource.customer.customer_type == 0"
                  label="Ad Soyad"
                >
                  <b-form-input
                    v-model="datasource.customer.title"
                    placeholder="ad ve soyadı lütfen tutarlı girin"
                    autocomplate="off"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="datasource.customer.customer_type == 1"
                  label="Firma Ünvanı"
                >
                  <b-form-input
                    v-model="datasource.customer.title"
                    placeholder=""
                    autocomplate="off"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="12" sm="12">
                <b-form-group label="GSM">
                  <b-form-input
                    type="number"
                    v-model="datasource.customer.gsm_primary"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="12" sm="12">
                <b-form-group label="Email">
                  <b-form-input
                    v-model="datasource.customer.email_primary"
                    type="email"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                v-if="datasource.customer.customer_type == 0"
                lg="6"
                sm="12"
              >
                <b-form-group label="Doğum Tarihi">
                  <flat-pickr
                    v-model="datasource.customer.dateofbirth"
                    class="form-control"
                    :config="{ dateFormat: 'd.m.Y', locale: 'tr' }"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="datasource.customer.customer_type == 0"
                lg="6"
                sm="12"
              >
                <b-form-group class="mt-1">
                  <label></label>
                  <b-form-checkbox
                    v-model="datasource.customer.sendsms_birthday"
                    value="1"
                    class="custom-control-primary"
                  >
                    Doğum gününde kutlama mesajı gönder
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col col lg="6" sm="12">
          <h5 class="">Cari Bilgileri</h5>
          <div class="cardbody mt-1">
            <b-row>
              <b-col
                v-if="datasource.customer.customer_type == 0"
                lg="6"
                sm="12"
              >
                <b-form-group label="TCKN">
                  <b-form-input
                    placeholder="fatura için gereklidir"
                    v-model="datasource.customer.taxtnumber"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                v-if="datasource.customer.customer_type == 1"
                lg="6"
                sm="12"
              >
                <b-form-group label="Vergi No">
                  <b-form-input
                    v-model="datasource.customer.taxtnumber"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col
                v-if="datasource.customer.customer_type == 1"
                lg="6"
                sm="12"
              >
                <b-form-group label="Vergi Dairesi">
                  <b-form-input
                    v-model="datasource.customer.taxtoffice"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col col col-6>
                <b-form-group label="Posta Kodu">
                  <b-form-input
                    v-model="datasource.customer.zipcode"
                    placeholder=""
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="12" sm="12">
                <b-form-group label="İl">
                  <v-select
                    :searchable="true"
                    :clearable="true"
                    v-model="datasource.customer.city_id"
                    :options="global.cities"
                    @input="getDistricts()"
                    :reduce="(curr) => curr.value"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12" sm="12">
                <b-form-group label="İlçe">
                  <v-select
                    :searchable="true"
                    :clearable="true"
                    v-model="datasource.customer.district_id"
                    :options="datasource.districts"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="12" sm="12">
                <b-form-group label="Açık Adres">
                  <b-form-textarea
                    v-model="datasource.customer.address"
                    placeholder="açık adres"
                    rows="4"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group v-if="datasource.customer.customer_type == 1">
                  <label></label>
                  <b-form-checkbox
                    v-model="datasource.customer.is_supplier"
                    value="1"
                    class="custom-control-primary"
                  >
                    Tedarkçi
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-card-footer
        class="
          p-0
          pt-2
          ml-n2
          mr-n2
          mt-2
          pl-2
          pr-2
          d-flex
          justify-content-between
        "
      >
        <button v-on:click="save()" class="btn btn-primary" type="button">
          Kaydet
        </button>
        <button
          @click="$emit('modalcardclose')"
          v-if="pagemode == 'modal'"
          class="btn btn-outline-primary"
          type="button"
        >
          Vazgeç
        </button>
      </b-card-footer>
    </b-form>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueAutosuggest } from "vue-autosuggest";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { nanoid, customAlphabet } from "nanoid";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    vSelect,
    flatPickr,
    VueAutosuggest,
  },
  props: {
    pagemode: { default: "page" },
  },
  data() {
    return {
      datasource: {
        customer: {
          customer_code: "",
          customer_type: 0,
          title: "",
          gsm_primary: "",
          email_primary: "",
          taxtnumber: "",
          taxtoffice: "",
          city_id: null,
          district_id: null,
          address: "",
          zipcode: "",
          dateofbirth: "",
          is_supplier: false,
        },
        cities: [],
        districts: [],
      },
    };
  },
  created() {
    if (this.pagemode == "page") {
      utils.setTitle("Müşteri Oluştur");
      if (!utils.userHasRole("create_customer")) {
        this.$router.push("/not-authorized");
        return;
      }
    }

    this.datasource.customer.customer_code = customAlphabet(
      "1234567890ABCDEFGHIJKLMNOPRSTUVYZQX",
      6
    ).apply();
  },
  computed: {
    ...mapState({
      global: (state) => state.global,
      sf: (state) => state.sf,
    }),
  },
  methods: {
    save() {
      let postData = {};
      Object.entries(this.datasource.customer).forEach((q) => {
        let key = q[0];
        let val = _.isObject(q[1]) ? q[1].value : q[1];
        postData[key] = val;
      });
      postData.sendsms_birthday = postData.sendsms_birthday == null ? 0 : 1;
      postData.is_supplier = postData.is_supplier == null ? 0 : 1;

      if (
        postData.customer_code.length < 6 ||
        postData.customer_code.length >= 12
      ) {
        utils.notificationToast(
          "warning",
          "Bilgi",
          "Lütfen geçerli bir müşteri kodu yazın."
        );
        return;
      }
      if (postData.customer_type == 0) {
        if (postData.title.length <= 3) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen ad ve soyadı yazın."
          );
          return;
        }
        if (postData.gsm_primary.length <= 9) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen gsm numarası yazın."
          );
          return;
        }
        if (postData.city_id <= 0) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen müşterinin bulunduğu şehiri seçin."
          );
          return;
        }
      }
      if (postData.customer_type == 1) {
        if (postData.title.length <= 3) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen firmanın adını yazın."
          );
          return;
        }
        if (postData.city_id <= 0) {
          utils.notificationToast(
            "warning",
            "Bilgi",
            "Lütfen firmanın bulunduğu şehiri seçin."
          );
          return;
        }
      }
      api.action("createCustomer", postData).then((q) => {
        if (q.success == false) {
          utils.notificationToast("warning", "Bilgi", q.message);
          return;
        }
 
        utils.notificationToast("success", "Bilgi", q.message);
        if (this.pagemode == "modal") {
          this.sf.customer = q.data;
          this.$emit('modalcardclose')
        } else {
          this.$router.push({
            name: "customer-update",
            query: { customer: q.data.customer_uniq },
          });
        }
      });
    },
    getDistricts() {
      let city_id = this.datasource.customer.city_id;
      this.datasource.customer.district_id = null;
      let items = utils.getDistricts(city_id, { viewtype: "vselect" });
      this.datasource.districts = items;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>